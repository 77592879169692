import axios from "axios";

const elasticsearchClient = axios.create({
  // baseURL: "https://localhost:9200", // Local
  baseURL: "https://elastic.lib.easybit.app/elasticsearch/",
  auth: {
    username: "elastic",
    // password: "GtAOl+qyh8pKl8Ux=9Jl", // Local
    password: "rkwKjvzYrFZlhaHbCBf-",
  },
  headers: {
    "Content-Type": "application/json",
  },
});

export const search = async (query, filters = {}, page = 0, pageSize = 10) => {
  try {
    const must = [
      {
        query_string: {
          query: `*${query}*`,
          fields: ["titulo", "autor", "fonte", "formato", "tamanho", "acessos"],
        },
      },
    ];

    // Aplicar filtros
    for (const [field, value] of Object.entries(filters)) {
      if (value) {
        must.push({ term: { [field]: value } });
      }
    }

    const response = await elasticsearchClient.post("/dominiopublico/_search", {
      from: page * pageSize, // Controla o início da paginação
      size: pageSize, // Controla o número de resultados por página
      query: {
        bool: { must },
      },
      aggs: {
        autores: { terms: { field: "autor.keyword" } },
        formatos: { terms: { field: "formato.keyword" } },
        fontes: { terms: { field: "fonte.keyword" } },
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar acervo no Elasticsearch:", error);
    return { hits: { hits: [] }, aggregations: {} };
  }
};
