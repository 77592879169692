import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { search } from "./elasticsearch";

function App() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [aggregations, setAggregations] = useState({});
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0); // Página atual
  const [pageSize] = useState(10); // Tamanho da página (número de resultados por página)
  const [totalResults, setTotalResults] = useState(0); // Total de resultados

  const totalPages = Math.ceil(totalResults / pageSize); // Calcula o total de páginas

  const handleSearch = async (e, page = 0) => {
    if (e) e.preventDefault();
    const data = await search(query, filters, page, pageSize);
    setResults(data.hits.hits);
    setAggregations(data.aggregations);
    setTotalResults(data.hits.total.value); // Armazena o total de resultados
    setCurrentPage(page); // Atualiza a página atual
  };

  const handleFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value });
    handleSearch(new Event("submit"), currentPage); // Mantém a página atual ao alterar o filtro
  };

  const handlePageChange = (event, page) => {
    handleSearch(null, page - 1); // Navega para a página selecionada (page começa de 1)
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Domínio Público</Typography>
        </Toolbar>
      </AppBar>

      <Container style={{ marginTop: "20px" }}>
        <Paper style={{ padding: "20px", marginBottom: "20px" }}>
          <h1>Busca no acervo</h1>
          <form onSubmit={handleSearch}>
            <TextField
              label="Digite o título do livro"
              variant="outlined"
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "10px" }}
            >
              Buscar
            </Button>
          </form>
        </Paper>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <h2>Refinar Busca</h2>
            {aggregations.autores && (
              <>
                <h3>Autor</h3>
                {aggregations.autores.buckets.map((bucket) => (
                  <FormControlLabel
                    key={bucket.key}
                    control={
                      <Checkbox
                        onChange={() =>
                          handleFilterChange("autor.keyword", bucket.key)
                        }
                      />
                    }
                    label={`${bucket.key} (${bucket.doc_count})`}
                  />
                ))}
              </>
            )}
            {aggregations.formatos && (
              <>
                <h3>Formato</h3>
                {aggregations.formatos.buckets.map((bucket) => (
                  <FormControlLabel
                    key={bucket.key}
                    control={
                      <Checkbox
                        onChange={() =>
                          handleFilterChange("formato.keyword", bucket.key)
                        }
                      />
                    }
                    label={`${bucket.key} (${bucket.doc_count})`}
                  />
                ))}
              </>
            )}
            {aggregations.fontes && (
              <>
                <h3>Fonte</h3>
                {aggregations.fontes.buckets.map((bucket) => (
                  <FormControlLabel
                    key={bucket.key}
                    control={
                      <Checkbox
                        onChange={() =>
                          handleFilterChange("fonte.keyword", bucket.key)
                        }
                      />
                    }
                    label={`${bucket.key} (${bucket.doc_count})`}
                  />
                ))}
              </>
            )}
          </Grid>

          <Grid item xs={9}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Autor</TableCell>
                  <TableCell>Fonte</TableCell>
                  <TableCell>Formato</TableCell>
                  <TableCell>Tam. Arquivo</TableCell>
                  <TableCell>Acessos</TableCell>
                  <TableCell>Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((book, index) => (
                  <TableRow key={index}>
                    <TableCell>{book._source.titulo}</TableCell>
                    <TableCell>{book._source.autor}</TableCell>
                    <TableCell>{book._source.fonte}</TableCell>
                    <TableCell>{book._source.formato}</TableCell>
                    <TableCell>{book._source.tamanho}</TableCell>
                    <TableCell>{book._source.acessos}</TableCell>
                    <TableCell>
                      <a
                        href={book._source.download_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="contained" color="primary">
                          Download
                        </Button>
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* Paginação */}
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={totalPages} // Número total de páginas
                page={currentPage + 1} // A página atual (paginação começa com 1)
                onChange={handlePageChange} // Manipulador de mudanças de página
                color="primary"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default App;
